module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en","fr"],"defaultLanguage":"de","siteUrl":"https://playalong.17hippies.de","redirect":false,"i18nextOptions":{"defaultNS":"common"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"17 HIPPIES PLAY ALONG","short_name":"PLAY ALONG","description":"Songs der 17 Hippies mit Noten und Playback zum Mitspielen.","start_url":"/","lang":"de","background_color":"#FAFAFA","theme_color":"#817425","display":"standalone","icons":[{"src":"/favicon.ico","sizes":"72x72","type":"image/ico"},{"src":"/mstile-70x70.png","sizes":"70x70","type":"image/png"},{"src":"/mstile-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/mstile-150x150.png","sizes":"150x150","type":"image/png"},{"src":"/mstile-310x310.png","sizes":"310x310","type":"image/png"},{"src":"/mstile-310x150.png","sizes":"310x150","type":"image/png"},{"src":"/apple-touch-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/apple-touch-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/apple-touch-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/apple-touch-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/apple-touch-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/apple-touch-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/android-chrome-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
